<template>
    <div class="box-layout">
      <HeaderHome> 
        <img slot="logo" src="../assets/img/home/DDGLogoTop.png" />
      </HeaderHome>
  
      <div class="margin-top-content"></div>
      <!-- Start Hero Area -->
      <SliderFour />
      <!-- Start Story Area -->
  
      <div class="mt--100 mt-sm-10">
        <About />
      </div>
      <!-- End Story Area -->
  
      <Footer />
    </div>
  </template>
  
  <script>
    import HeaderHome from "../components/header/HeaderHome";
    import SliderFour from "../components/slider/SliderFour";
    import About from "../components/about/About";
    import Footer from "../components/footer/Footer";
    export default {
      components: {
        HeaderHome,
        SliderFour,
        About,
        Footer,
      },
      data() {
        return {
          aboutSrc: require("../assets/img/portfolio/about-image.jpg"),
        };
      },
    };
  </script>
  
  <style lang="scss" scoped>
    .box-layout {
      .-rn-footer {
        background: #f9f9f9;
      }
      header.header.header-flat {
        padding: 0;
      }
    }
  </style>
  <style lang="scss">
    .box-layout {
      header.header.header-flat,
      .v-toolbar__content {
        padding: 0 10px;
      }
      .mt--100.mt-sm-10 {
        margin-top: 50px;
      }
    }
    body {
      margin-top: 116px;
    }
  </style>
  
<template>
  <!-- Footer -->
  <div class="copyright">
    <v-container fluid>
      <v-row>
          <div class="copyright-inner">
            <p>
              Copyright de data gastronoom - All Rights Reserved - 
              <a
                href="./privacy-policy/"
                target="_blank"
                >Privacy policy</a
              >
            </p>
          </div>
      </v-row>
    </v-container>
  </div>
  <!--// Footer -->
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss">
  .copyright-inner {
    p {
      a {
        color: #2d1d49;
        &:hover {
          color: #573b8d;
        }
      }
    }
  }
</style>

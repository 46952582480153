<template>
  <div class="about-page">

    <div class="rn-story-area d-flex bg_color--4" id="info">
      
      <div class="rn-story-right w-100 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              Al 300 exemplaren verkocht in de eerste 9 dagen
            </h2>
            <p>
              Zeer positief ontvangen bij directeuren, managers en specialisten
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
    <!-- Start Skill Area -->
    <div class="rn-story-area d-flex bg_color--5" id="">
      <div
        class="rn-story-left w-50 rn-story-bg-wrapper"
        :style="{ backgroundImage: 'url(' + aboutSrc + ')' }"
      ></div>
      <div class="rn-story-right w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              Bestel dit boek voor slechts <br /> 12,95
            </h2>
            <p>
              Beste bezoeker,
            </p>
            <p>
              Het boek “De Data Gastronoom” is een leuk verhaal over data
              projecten (business intelligence). We volgen twee heren, oude
              schoolvrienden, die in een gezellig restaurant een aantal
              uiteenlopende onderwerpen bespreken, die we dagelijks op kantoor
              meemaken.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
    <!-- Start Skill Area -->
    <div class="rn-story-area d-flex bg_color--4" id="">
      
      <div class="rn-story-right w-100 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              "Ik heb het boek met plezier en op z'n tijd een glimlach gelezen"
            </h2>
            <p>
              "Heel knap hoe jullie op een heel eenvoudige en beeldende manier alle
              jargon rondom datawarehousing hebben kunnen vatten in een sprekend verhaal.
              Wat ik vooral heel nuttig vindt zijn de metaforen die jullie hebben bedacht;
              dat maakt het voor mensen die ver van BI af staan toch eenvoudiger om mee te
              kunnen denken en praten als het over datawarehousing gaat."
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
    <!-- Start Skill Area -->
    <div class="rn-story-area d-flex bg_color--5" id="">
      <div class="rn-story-left w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              VARIËTEIT
            </h2>
            <p>
              Er worden veel uiteenlopende onderwerpen behandeld. Zoals big data, scrum,
              teamsamenstelling en valorisatie. 13 hoofdstukken, 13 verschillende
              onderwerpen over data.
            </p>
          </div>
        </div>
      </div>
      <div class="rn-story-right w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              LEEST MAKKELIJK
            </h2>
            <p>
              136 pagina’s in handzaam formaat en duidelijke letters! Ideaal voor in de
              trein of thuis op de bank (of op kantoor natuurlijk).
            </p>
          </div>
        </div>
      </div>
      <div class="rn-story-right w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              OP VOORRAAD
            </h2>
            <p>
              Boeken worden binnen 1-3 werkdagen verzonden. Vandaag bestellen en binnen
              3 werkdagen lezen.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
    <!-- Start Skill Area -->
    <div class="rn-story-area d-flex bg_color--6" id="over">
      <div class="rn-story-left w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <div class="person">
              <img src="../../assets/img/home/Rick_vanVliet_rond.png" alt="Rick van Vliet">
            </div>
            <h2>
              Rick van Vliet
            </h2>
            <p>
              Sinds 1999 is hij in dienst bij VX Company en de laatste 15 jaar
              nadrukkelijk bezig met alle aspecten van business intelligence,
              van projecten bij de klant tot het geven van advies, cursussen en
              presentaties. Zijn passie ligt bij de techniek en de vertaalslag naar
              de klant. Zij kunnen zo gefundeerd een keuze maken voor een goede data
              oplossing.
            </p>
          </div>
        </div>
      </div>
      <div class="rn-story-right w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <div class="person">
              <img src="../../assets/img/home/TijsvanRinsum_Rond.png" alt="Tijs van Rinsum">
            </div>
            <h2>
              Tijs van Rinsum
            </h2>
            <p>
              Direct na het afstuderen in 2005 is hij zich gaan verdiepen in data
              en business intelligence. Jarenlang heeft hij bij verschillende klanten
              oplossingen ontworpen en ingericht. Hij weet data op de juiste manier te
              behandelen waardoor data pas echt toegevoegde waarde heeft voor de dagelijkse
              processen en lange termijn beslissingen.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
  </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        aboutSrc: require("../../assets/img/home/Upright_hardcover_boek_datagastronoom.png"),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .about-page {
    .rn-skill-right {
      @media only screen and (max-width: 767px) {
        order: 2;
      }
    }
  }
  .person {
    width: 125px;
  }
</style>

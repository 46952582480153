<template>
    <div class="box-layout">

      <div>
        <h1>Privacyverklaring</h1>
        <p>
          De data gastronoom, de auteurs, (”’wij”), gevestigd in Nederland, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
        </p>
        <p>
          De data gastronoom acht zorgvuldige verwerking van persoonsgegevens van het grootste belang. Persoonsgegevens worden zorgvuldig verwerkt en beveiligd. Bij de verwerking houden we ons aan de vereisten van de Algemene Verordening Gegevensbescherming (de “AVG”). Deze Privacyverklaring zet uiteen hoe wij uw gegevens verzamelen en gebruiken, in overeenstemming met de AVG.
        </p>
        <p>
          In deze Privacyverklaring leggen wij uit welke persoonsgegevens wij verzamelen en gebruiken, en met welk doel wij dat doen. Wij raden u aan deze Privacyverklaring zorgvuldig te lezen. Deze Privacyverklaring heeft betrekking op onze website, https://qvada.com en gerelateerde websites en Diensten.
        </p>
        <p>
          Deze Privacyverklaring is voor het laatst aangepast op: 4 mei 2019
        </p>
        <p>
          Gebruik van persoonsgegevens
        </p>
        <p>
          Als u gebruik maakt van websites en gerelateerde Diensten die de data gastronoom aanbiedt, dan kunt u persoonsgegevens met ons delen. Wij verzamelen en maken gebruik van persoonsgegevens die u, of uw bedrijf, direct met ons deelt. Wij zullen de persoonsgegevens niet voor andere doeleinden gebruiken dan beschreven in deze verklaring, tenzij u daar vooraf toestemming voor heeft gegeven.
        </p>
        <p>
          Soorten gegevens verzameld
        </p>
        <p>
          Wanneer u gebruik maakt van onze Diensten, dan kunnen we de volgende gegevens verzamelen:
        </p>
        <ul>
          <li>Voor- en achternaam</li>
          <li>Adresgegevens</li>
          <li>Telefoonnummer</li>
          <li>E-mailadres</li>
          <li>IP-adres</li>
          <li>Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
          <li>Gegevens over uw activiteiten op onze website</li>
          <li>Gegevens over uw surfgedrag over verschillende websites heen (bijvoorbeeld omdat dit bedrijf onderdeel is van een advertentienetwerk)</li>
          <li>Internetbrowser en apparaat type</li>
          <li>Bankrekeningnummer</li>
        </ul>
        <p>Gronden voor de verwerking</p>
        <p>Wij verwerken uw persoonsgegevens in het algemeen voor de volgende doeleinden:</p>
        <ul>
          <li>Het afhandelen van uw betaling</li>
          <li>Verzenden van onze nieuwsbrief en/of reclamefolder</li>
          <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
          <li>U te informeren over wijzigingen van onze diensten en producten</li>
          <li>De data gastronoom analyseert uw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op uw voorkeuren.</li>
          <li>De data gastronoom verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte.?</li>
        </ul>
        <p>Wij verzamelen uw persoonsgegevens op basis van de gerechtvaardigde belangen of uw toestemming, die u te allen tijde kunt intrekken.</p>
        <p>Bewaren van persoonsgegevens</p>
        <p>Wij bewaren uw persoonsgegevens zo lang als nodig is ter realisering van de doeleinden van deze Privacyverklaring. In het algemeen bewaren we persoonsgegevens van onze websitebezoekers voor 24 maanden.</p>
        <p>Uw rechten</p>
        <p>U heeft het recht op inzage van de informatie die wij verwerken. Als u dat wilt, kunt u direct contact met ons opnemen. We zorgen ervoor dat we u een kopie bezorgen van de gegevens die we over u verwerken. Mogelijk moeten we uw identiteit verifi?ren om aan uw verzoek te voldoen. Als u gelooft dat de verstrekte informatie die we over u verwerken niet juist is, dan kunt u contact met ons opnemen om uw informatie te updaten. U heeft ook het recht om bezwaar te maken tegen de gegevensverwerking. Als u wilt dat wij uw informatie verwijderen, dan kunt u ons op ieder moment informeren. U kunt op elk moment contact met ons opnemen met betrekking tot deze verzoeken. We zullen altijd tijdig reageren, in overeenstemming met de AVG. Mocht u niet tevreden zijn met het antwoord dat u ontvangt, dan kunt u uw klacht voorleggen aan de betreffende toezichthouder.</p>
        <p>Delen met anderen</p>
        <p>We zullen uw informatie niet delen met anderen zonder uw toestemming, tenzij dit noodzakelijk is in het kader van de uitvoering van de overeenkomst die u, of uw organisatie, met ons heeft afgesloten, of tenzij dit een wettelijke verplichting is.</p>
        <p>Doorgifte van persoonsgegevens</p>
        <p>Ter realisering van de doeleinden beschreven in deze Privacyverklaring kunnen wij uw gegevens buiten de EER overdragen. Wanneer dit het geval is, zullen wij de juiste maatregelen nemen om er zeker van te zijn dat persoonsgegevens goed beveiligd zijn. Voor overdrachten naar de Verenigde Staten zorgen wij ervoor dat een dergelijke Amerikaanse partij aan het Privacy Shield voldoet. Voor andere overdrachten, kunnen we uw gegevens overdragen aan een door de Europese Commissie erkend land dat met betrekking tot Persoonsgegevens een passend beschermingsniveau biedt.</p>
        <p>Analytics</p>
        <p>Gegevens die u verstrekt, kunnen worden bewaard voor het maken van statistische analyses, waaronder via Google Analytics en Piwik. Deze statistieken worden gebruikt om de Diensten te optimaliseren. Zo kunnen wij zien hoe onze bezoekers de website gebruiken en op basis daarvan de website verbeteren. Waar mogelijk, zullen we deze gegevens pseudonimiseren.</p>
        <p>Cookies</p>
        <p>Wij willen u op een gebruiksvriendelijke manier relevante en persoonlijke informatie kunnen bieden. Hiervoor maken wij op onze websites gebruik van verschillende soorten cookies. Wij plaatsen bijvoorbeeld cookies die noodzakelijk zijn voor het functioneren van de site. Denk hierbij aan de mogelijkheid tot reageren, de werking van spamfilters, het afspelen van video en caching om de website sneller te maken. Ook gebruiken wij analytische cookies die geen inbreuk maken op uw privacy.</p>
        <p>In het algemeen worden cookies bewaard voor de duur van de (browser)sessie. Bovendien kan uw IP-adres worden opgeslagen in de logbestanden van onze webserver als u van onze Diensten gebruik maakt. Dit is noodzakelijk om onze Diensten op de juiste manier te beheren en te beveiligen. We gebruiken uw IP-adres niet om uw onlinegedrag te volgen of te registeren. Meestal zijn we niet in staat om uw IP-adres te koppelen aan uw naam, adres of andere identificerende informatie, behalve wanneer u ons dergelijke informatie verstrekt en gebruik blijft maken van hetzelfde IP-adres.</p>
        <p>U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.</p>
        <p>Beveiliging</p>
        <p>We zorgen ervoor dat uw persoonsgegevens tegen verlies of tegen onrechtmatige verwerking worden beveiligd. Wij hebben onder andere de volgende technische en organisatorische maatregelen genomen:</p>
        <ul>
          <li>Logische toegangscontrole, gebruik van wachtwoorden en mogelijk een tweede factor;</li>
          <li>Encryptie van data;</li>
          <li>Pseudonimiseren van data;</li>
          <li>Het gebruik van Virtual Prilite Servers (VPN) toegankelijk met de SSH-sleutel;</li>
          <li>Overdrachten alleen over SSli</li>
          <li>Purpose-bound toegangsbeperlingen;</li>
          <li>Controle van erkende autorilities.</li>
        </ul>
        <p>Websites en Diensten van derden</p>
        <p>Onze Diensten omvatten activiteiten gerelateerd aan het leveren van software waarmee onze klanten hun eigen professionele websites kunnen bouwen. Onze klanten bepalen wat soort gegevens zij verzamelen op of via hun website. We verwerken dergelijke gegevens alleen namens onze klanten. Als u vragen heeft over informatie die onze klanten op of via hun website verzamelen, dan kunt u contact opnemen met de organisatie die de website heeft opgezet. Onze klanten bepalen welke andere diensten of plug-ins ze gebruiken. Deze Privacyverklaring is niet van toepassing op dergelijke andere diensten of plug-ins, noch op websites van derden die worden bezocht via links. We kunnen niet garanderen dat deze derden uw persoonlijke gegevens veilig en betrouwbaar behandelen. Wij adviseren u om de privacyverklaringen van deze partijen te lezen.</p>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      components: {
      },
      data() {
        return {
        };
      },
    };
  </script>
  
  <style lang="scss" scoped>
    .box-layout {
      .-rn-footer {
        background: #f9f9f9;
      }
      header.header.header-flat {
        padding: 0;
      }
    }
  </style>
  <style lang="scss">
    .box-layout {
      header.header.header-flat,
      .v-toolbar__content {
        padding: 0 10px;
      }
      .mt--100.mt-sm-10 {
        margin-top: 50px;
      }
    }
  </style>
  